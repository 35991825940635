<template>
  <div class="input-list">
    <div class="icon-box">
      <img
        class="flag-icon"
        src="../assets/images/register/flag-icon.png"
        alt=""
      />
    </div>
    <div class="c-channel-box">
      <div @click.stop="goldChanneShow" class="c-channel">    
        <div class="c-channel-data">
          <p class="name dian1">{{selectCountry.displayName}}</p>
          <input type="hidden" :value="selectCountry.countryId">
        </div>
        <img class="xl-icon" src="@/assets/images/transaction/xl-icon.png" alt="">
      </div>
      <div v-if="goldChanneState" class="channer-menu">
        <div  class="c-channel" v-for="(item,index) in countryList.countrys" :key="index" @click="selectGoldChanne(item)">
          <img class="rjfs-icon menu-channel" :src="'https://images.'+domain+item.logo" alt="">
          <div class="c-channel-data">
            <p class="name">{{ item.displayName }}</p>
            <input type="hidden" :value="item.countryId">
          </div>
        </div>
      </div> 
    </div>
    <!-- <select v-model="countryList.countryActive"  @change="changeCountry($event)">
      <option value="" style="display: none;color:#c1c1c1" disabled selected>{{$t('loginRegister.countryInput')}}</option>
      <option v-for="(country,index ) in countryList.countrys" :value="country.countryId" :key="index"><img src="../assets/images/register/flag-icon.png" alt=""> {{country.displayName}}</option>
    </select> -->
    <p v-show="errShowList.errShow" class="err-text">
      <img src="../assets/images/register/dx-icon.png" alt="" />
      <span>{{errShowList.errContent}}</span>
    </p>
  </div>
</template>

<script>
export default{
  props: {
    errShowList: {
      type: Object,
      default () {
        return {}
      }
    },
    countryList: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      goldChanneState:false,
      selectCountry:{
        displayName:this.$i18n.t('loginRegister.countryInput'),
        countryId:''
      },
      domain:'',
    }
  },
  created() {
    this.domain = window.location.host.split(".").length > 2 ? window.location.host.split(".")[1]+"."+window.location.host.split(".")[2] : window.location.host ;
  },
  methods: {
    // 点击显示隐藏国家列表
    goldChanneShow(){
      this.goldChanneState = !this.goldChanneState
    },
    // 点击下拉国家关闭下拉
    selectGoldChanne(channel){
      this.goldChanneState = false;
      this.selectCountry = channel;
      this.$emit('country', channel.countryId) 
      console.log("你选中了",channel.countryId)
    },
    countryShow(){   
        this.goldChanneState = false;
    },
    // changeCountry(event) {
    //   this.countryActive = event.target.value; //获取option对应的value值
    //   console.log("你选中了",this.countryActive)
    //   this.$emit('country', this.countryActive) 
    // },
  },
  mounted () {
    document.addEventListener('click', this.countryShow)
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-list{width: 385px;height: 55px;margin-bottom: 28px;border-radius: 5px;border: 1px solid #e0e0e0;position: relative; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .err-text{font-size: 12px;line-height: 14px; color:#fa885b;position:absolute;bottom: -22px;left: 5px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .err-text img{width: 12px;height: 12px;margin-right: 5px;}
.input-list .icon-box{width: 58px;text-align: center;}
.input-list .flag-icon{width: 22px;height: 22px;}
.input-list select{width: 78%;height: 40px;line-height: 40px; padding: 0 15px; border-left: 1px solid #e0e0e0;cursor: pointer;}
.input-list select option{padding: 10px 0;color: #000;}

.input-list .c-channel-box{position: relative;width: 78%;height: 20px;line-height: 20px;padding: 0 20px;border-left: 1px solid #e0e0e0;cursor: pointer;}
.input-list .c-channel{width: 100%;height: 100%;position: relative;cursor: pointer; border-radius: 5px; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .c-channel .rjfs-icon{width: 35px;height: 22px;margin: 0 16px;}
.input-list .c-channel .name{font-size: 15px;color: #4c4c4c;}
.input-list .c-channel .xl-icon{width: 14px;height: 9px;position: absolute;top: 8px;right: 0;}
.input-list .c-channel-box .channer-menu{width: 385px;background: #fff; position:absolute;top: 45px;left: -58px;z-index: 10;border: 1px solid #e0e0e0;border-radius: 5px;}
.input-list .channer-menu .c-channel{width: 90%;height: 55px;line-height:55px;padding: 0; margin: auto; border: none;border-bottom: 1px solid #f2f2f2;border-radius: 0;}
.input-list .channer-menu .c-channel .menu-channel{margin-left: 0;border: 1px solid #f2f2f2;}
.input-list .channer-menu .c-channel:hover .name{color: #fed849;}
.input-list .channer-menu .c-channel:last-child{border: none;}



@media screen and (max-width: 768px) {
.input-list{width: 100%;height: 2.5rem;margin-bottom: 1.5rem;border-radius: 5px;border: 1px solid #e0e0e0;position: relative; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .icon-box{width: 2.5rem;text-align: center;}
.input-list .err-text{font-size: 0.6rem;line-height: 0.8px; color:#fa885b;position:absolute;bottom: -1rem;left: 0.3rem;display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .err-text img{width: 0.6rem;height: 0.6rem;margin-right: 0.3rem;}
.input-list .flag-icon{width: 1.2rem;height: 1.2rem;}
.input-list select{width: 78%;padding: 0 0.6rem;height: 2rem;line-height: 2rem; line-height: 1.2rem;height: 1.2rem;border-left: 1px solid #e0e0e0;}
.input-list select option{padding: 0.5rem 0;color: #000;}

.input-list .c-channel-box{position: relative;width: 78%;padding: 0 0.8rem;height: 2rem;line-height: 2rem; line-height: 1.2rem;height: 1.2rem;margin: auto;border-left: 1px solid #e0e0e0;}
.input-list .c-channel{width: 100%;height: 100%;position: relative;cursor: pointer;border-radius: 5px; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.input-list .c-channel .rjfs-icon{width: 1.6rem;height: 1rem;margin: 0 0.8rem;}
.input-list .c-channel .name{font-size: 0.7rem;color: #4c4c4c;}
.input-list .c-channel .xl-icon{width: 0.7rem;height: 0.5rem;position: absolute;top: 0.4rem;right: 0;}
.input-list .c-channel-box .channer-menu{width: 16.25rem;margin: auto;padding: 0; background: #fff; position:absolute;top: 2.2rem;left: -2.6rem;z-index: 10;border: 1px solid #e0e0e0;border-radius: 5px;}
.input-list .channer-menu .c-channel{width: 90%;height: 2.5rem;line-height: 2.5rem; padding: 0;margin: auto; border: none;border-bottom: 1px solid #f2f2f2;border-radius: 0;}
.input-list .channer-menu .c-channel .menu-channel{margin-left: 0;}
.input-list .channer-menu .c-channel:hover .name{color: #fed849;}
.input-list .channer-menu .c-channel:last-child{border: none;}

}
</style>
